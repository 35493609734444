body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.item {
  position: relative;
  margin: 10px;
  cursor: pointer;
  width: 144px;
}

.item.selected {
  outline: 2px solid #1890ff;
  outline-offset: 5px;
}

.cover {
  width: 100%;
  padding-top: 56.25%;
  background-color: #1c1e26;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: contain;
}

.duration {
  position: absolute;
  top: 4px;
  right: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  color: #eeeeee;
}

.title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ant-tabs.ant-tabs-top {
  overflow: auto;
}

.LeftRadio .biz-ant-radio-button-wrapper {
  border-radius: 2px !important;
}

.biz-ant-modal-header {
  padding-left: 10px;
}

.biz-ant-modal-body {
  display: flex;
}

.LeftRadio {
  width: 200px !important;
  max-width: 200px !important;
  padding: 0 10px;
}

.biz-ant-radio-button-wrapper {
  margin: 5px 0;
  background: #45474b !important;
  color: #fff;
  border: none !important;
}

.biz-ant-modal-content {
  background: #25272b !important;
}

.biz-ant-modal-header {
  background: #25272b !important;
}

.biz-ant-modal-title {
  color: #fff !important;
}

.biz-ant-modal-close-x {
  color: #fff !important;
}

.biz-ant-tree .biz-ant-tree-treenode-disabled .biz-ant-tree-node-content-wrapper {
  color: #fff !important;
}

:where(.css-dev-only-do-not-override-qgg3xn).biz-ant-tree .biz-ant-tree-node-content-wrapper {
  margin: 5px 0 !important;
}

.biz-ant-tree {
  background: #25272b !important;
}

.biz-ant-tree-node-selected {
  background: #000 !important;
}



.biz-ant-radio-button-checked {
  background: #000 !important;
}

.biz-ant-radio-button-wrapper:not(:first-child)::before {
  display: none !important;
}

.biz-ant-pagination-item a {
  color: #fff !important;
}

.biz-ant-pagination-item-active a {
  color: #000 !important;
}

.biz-ant-pagination-item-link {
  color: #fff !important;
}

.biz-ant-pagination-item-ellipsis {
  color: #fff !important;
}

.biz-ant-btn-primary:disabled {
  color: #fff !important;
}